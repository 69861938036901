import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState, setPermissions } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import {setMobileApplication} from "../../store/theme/themeSlice";

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const defaultMobileApplicationLogo = useSelector(
        (state) => state.theme.mobileApplicationLogo
    )

    const defaultMobileApplication = useSelector(
        (state) => state.theme.mobileApplication
    )

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)

            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    const permissions = resp.data.user.roles.flatMap(role =>
                        role.methods.map(method => ({
                            controller: method.controller_permission.controller,
                            method: method.method,
                            mobileApplication: method.pivot.mobile_application_id
                        }))
                    )

                    dispatch(
                        setUser(
                            {...resp.data.user, permissions} || {
                                avatar: '',
                                username: 'Anonymous',
                                authority: [],
                                roles: [],
                                email: '',
                            }
                        )
                    )

                    if (!defaultMobileApplicationLogo || !defaultMobileApplication) {
                        dispatch(setMobileApplication({value: resp.data.mobile_application_id, logo: resp.data.mobile_application_logo}))
                    }
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signOut,
    }
}

export default useAuth
