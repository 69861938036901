const appConfig = {
    apiPrefix: 'https://api-mobile.dev.atvrom.ro/api',
    // apiPrefix: 'http://atvrom.man/api',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'ro',
    localeId: 'ro',
    enableMock: false,
}

export default appConfig
