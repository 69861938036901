import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'
import {themeConfig} from "../configs/theme.config"

const unauthorizedCode = [401, 403]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

BaseService.interceptors.request.use(
    (config) => {
        let language = localStorage.getItem('language')
        let currency = localStorage.getItem('currency')
        let mobileApplication = localStorage.getItem('mobileApplication')

        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        mobileApplication = mobileApplication ? JSON.parse(mobileApplication) : themeConfig.application
        language = language ? JSON.parse(language) : 1
        currency = currency ? JSON.parse(currency) : 1

        let accessToken = persistData.auth.session.token

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`
        }

        const separator = config.url.includes('?') ? '&' : '?'
        config.url += `${separator}mobileApplication=${mobileApplication}&language=${language}&currency=${currency}`

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }

        return Promise.reject(error)
    }
)

export default BaseService
