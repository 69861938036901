import {createContext, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'

const ErrorContext = createContext()

export const ErrorProvider = ({ children }) => {
    const [errorsAPI, setErrorsAPI] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const location = useLocation()

    useEffect(() => {
        setServerErrors({})
    }, [location])

    const setServerErrors = errorObject => {
        setErrorsAPI(errorObject.errors || {})
        setErrorMessage(errorObject.message || '')
    }

    return (
        <ErrorContext.Provider value={{ errorsAPI, errorMessage, setServerErrors }}>
            {children}
        </ErrorContext.Provider>
    )
}

export default ErrorContext
