import { createSlice } from '@reduxjs/toolkit'
import { themeConfig } from 'configs/theme.config'
import {
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_STACKED_SIDE,
    NAV_MODE_TRANSPARENT,
    NAV_MODE_LIGHT,
    NAV_MODE_DARK,
    NAV_MODE_THEMED,
    MODE_DARK,
    MODE_LIGHT,
    LAYOUT_TYPE_DECKED,
} from 'constants/theme.constant'

const initialNavMode = () => {
    if (
        themeConfig.layout.type === LAYOUT_TYPE_MODERN &&
        themeConfig.navMode !== NAV_MODE_THEMED
    ) {
        return NAV_MODE_TRANSPARENT
    }

    return themeConfig.navMode
}

const initialState = {
    themeColor: localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : themeConfig.themeColor,
    direction: localStorage.getItem('direction') ? localStorage.getItem('direction') : themeConfig.direction,
    mode: localStorage.getItem('mode') ? localStorage.getItem('mode') : themeConfig.mode,
    primaryColorLevel: localStorage.getItem('primaryColorLevel') ? localStorage.getItem('primaryColorLevel') : themeConfig.primaryColorLevel,
    panelExpand: themeConfig.panelExpand,
    navMode: localStorage.getItem('navMode') ? localStorage.getItem('navMode') : initialNavMode(),
    layout: localStorage.getItem('layout') ? JSON.parse(localStorage.getItem('layout')) : themeConfig.layout,
    mobileApplication: localStorage.getItem('mobileApplication') ? JSON.parse(localStorage.getItem('mobileApplication')) : themeConfig.application,
    mobileApplicationLogo: localStorage.getItem('mobileApplicationLogo') ? localStorage.getItem('mobileApplicationLogo') : null,
    language: localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : 1
}

const availableNavColorLayouts = [
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
]

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload

            localStorage.setItem('language', action.payload)
        },
        setMobileApplication: (state, action) => {
            state.mobileApplication = action.payload.value
            state.mobileApplicationLogo = action.payload.logo

            localStorage.setItem('mobileApplication', action.payload.value)
            localStorage.setItem('mobileApplicationLogo', action.payload.logo)
        },
        setDirection: (state, action) => {
            state.direction = action.payload

            localStorage.setItem('direction', action.payload)
        },
        setMode: (state, action) => {
            const availableColorNav = availableNavColorLayouts.includes(
                state.layout.type
            )

            if (
                availableColorNav &&
                action.payload === MODE_DARK &&
                state.navMode !== NAV_MODE_THEMED
            ) {
                state.navMode = NAV_MODE_DARK
            }
            if (
                availableColorNav &&
                action.payload === MODE_LIGHT &&
                state.navMode !== NAV_MODE_THEMED
            ) {
                state.navMode = NAV_MODE_LIGHT
            }
            state.mode = action.payload

            localStorage.setItem('mode', action.payload)
        },
        setLayout: (state, action) => {
            state.cardBordered = action.payload === LAYOUT_TYPE_MODERN
            if (action.payload === LAYOUT_TYPE_MODERN) {
                state.navMode = NAV_MODE_TRANSPARENT
            }

            const availableColorNav = availableNavColorLayouts.includes(
                action.payload
            )

            if (availableColorNav && state.mode === MODE_LIGHT) {
                state.navMode = NAV_MODE_LIGHT
            }

            if (availableColorNav && state.mode === MODE_DARK) {
                state.navMode = NAV_MODE_DARK
            }

            state.layout = {
                ...state.layout,
                ...{ type: action.payload },
            }

            localStorage.setItem('layout', JSON.stringify({
                ...state.layout,
                ...{ type: action.payload },
            }))
        },
        setPreviousLayout: (state, action) => {
            state.layout.previousType = action.payload
        },
        setSideNavCollapse: (state, action) => {
            state.layout = {
                ...state.layout,
                ...{ sideNavCollapse: action.payload },
            }
        },
        setNavMode: (state, action) => {
            if (action.payload !== 'default') {
                state.navMode = action.payload

                localStorage.setItem('navMode', action.payload)
            } else {
                if (state.layout.type === LAYOUT_TYPE_MODERN) {
                    state.navMode = NAV_MODE_TRANSPARENT

                    localStorage.setItem('navMode', NAV_MODE_TRANSPARENT)
                }

                const availableColorNav = availableNavColorLayouts.includes(
                    state.layout.type
                )

                if (availableColorNav && state.mode === MODE_LIGHT) {
                    state.navMode = NAV_MODE_LIGHT

                    localStorage.setItem('navMode', NAV_MODE_LIGHT)
                }

                if (availableColorNav && state.mode === MODE_DARK) {
                    state.navMode = NAV_MODE_DARK

                    localStorage.setItem('navMode', NAV_MODE_DARK)
                }
            }
        },
        setPanelExpand: (state, action) => {
            state.panelExpand = action.payload
        },
        setThemeColor: (state, action) => {
            state.themeColor = action.payload

            localStorage.setItem('themeColor', action.payload)
        },
        setThemeColorLevel: (state, action) => {
            state.primaryColorLevel = action.payload

            localStorage.setItem('primaryColorLevel', action.payload)
        },
    },
})

export const {
    setDirection,
    setMode,
    setLayout,
    setSideNavCollapse,
    setNavMode,
    setPanelExpand,
    setThemeColor,
    setThemeColorLevel,
    setPreviousLayout,
    setMobileApplication,
    setLanguage,
} = themeSlice.actions

export default themeSlice.reducer
