import React, { createContext, useState, useEffect } from 'react'
import ApiService from "../services/ApiService"

export const LanguageContext = createContext()

export const LanguageProvider = ({ children }) => {
    const [languages, setLanguages] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setIsLoading(true)
        ApiService.fetchData({
            url: `/languages/list`,
            method: 'post',
        })
            .then(response => {
                setLanguages(response.data.data)
                setIsLoading(false)
            })
            .catch(error => {
                console.error('Error:', error)
                setIsLoading(false)
            })
    }, [])

    return (
        <LanguageContext.Provider value={{ languages, isLoading }}>
            {children}
        </LanguageContext.Provider>
    )
}